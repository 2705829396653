import React, { useState } from "react";
import { Form, Alert, InputGroup } from "react-bootstrap";
import BookDataCareer from "../services/book.student";

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'


const AddStudent = () => {
    const [title, setTitle] = useState("");
    const [subject, setSubject] = useState("");
    const [presentaddress, setPresentaddress] = useState("");
    const [dateofbirth, setDateofbirth] = useState("");
    const [gender, setGender] = useState("");
    const [nationality, setNationality] = useState("");
    const [classappeasesexam, setClassappeasesexam] = useState("");
    const [mothertongue, setMothertongue] = useState("");

    const [mobile, setMobile] = useState("");
    const [guardianname, setGuardianname] = useState("");
    const [status, setStatus] = useState("Unpaid");
    const [flag, setFlag] = useState(true);
    const [massage, setMassage] = useState({ error: false, msg: "" });


    const [selectedDate, setSelectedDate] = useState(null)
    const [selectedTime, setSelectedTime] = useState(null)

    const [startDate, setStartDate] = useState(new Date());


    const handleSubmit = async (e) => {
        e.preventDefault();
        setMassage("");
        if (title === "" || mobile === "" || guardianname === "" || presentaddress === "" || subject === "" || dateofbirth === "" || gender === "" || nationality === "" || classappeasesexam === "" || mothertongue === "") {
            setMassage({ error: true, msg: "All fields are mandatory!" });
            return;
        }
        const newBook = {
            title,
            mobile,
            guardianname,
            dateofbirth,
           
            gender,
            nationality,
            mothertongue,
            classappeasesexam,
            presentaddress,
            subject,
            status
        }
        console.log(newBook);

        try {
            await BookDataCareer.addBooks(newBook);
            setMassage({ error: false, msg: "Submited Successfuly! " });
        } catch (err) {
            setMassage({ error: true, msg: err.massage });
        }

        setTitle("");
        setMobile("");
        setGuardianname("");
        setDateofbirth("");
        setGender("");
        setSelectedDate("")
        setNationality("");
        setClassappeasesexam("");
        setMothertongue("");

        setPresentaddress("");
        setSubject("");
    };



    return (
        <>






            {massage?.msg && (<Alert variant={massage?.error ? "danger" : "success"}
                dismissible
                onClose={() => setMassage("")}
            >
                {""}
                {massage?.msg}
            </Alert>
            )}

            <Form onSubmit={handleSubmit} >
                <div className="album py-2">
                    <div className="container">
                        <div className="row ">

                            <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="formBookTitle">
                                    <InputGroup>
                                        <Form.Control type="text"
                                            placeholder="Name of the Candidate"

                                            value={title}
                                            onChange={(e) => setTitle(e.target.value)}
                                        />
                                    </InputGroup>
                                </Form.Group>


                            </div>


                            <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="formBookGuardianname">
                                    <Form.Control type="text"
                                        placeholder="Guardian's Name"
                                        value={guardianname}
                                        onChange={(e) => setGuardianname(e.target.value)}
                                    />

                                </Form.Group>

                            </div>
                            <div className="col-md-6">

                                <Form.Group className="mb-3" controlId="formBookPresentaddress">

                                    <Form.Control type="text"
                                        placeholder="Present Full Address"
                                        value={presentaddress}
                                        onChange={(e) => setPresentaddress(e.target.value)}
                                    />

                                </Form.Group>
                            </div>
                            
                            <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="formBookDateofbirth">
                                    <Form.Control type="text"
                                        placeholder="Date of Birth "
                                        value={dateofbirth}
                                        onChange={(e) => setDateofbirth(e.target.value)}
                                    />
                                </Form.Group>
                            </div>

                            <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="formBookGender">
                                    <Form.Control type="text"
                                        placeholder="Gender- Male/Female/Other"
                                        value={gender}
                                        onChange={(e) => setGender(e.target.value)}
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="formBookNationality">
                                    <Form.Control type="text"
                                        placeholder="Nationality"
                                        value={nationality}
                                        onChange={(e) => setNationality(e.target.value)}
                                    />
                                </Form.Group>
                            </div>


                            <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="formBookMobile">

                                    <Form.Control type="text"
                                        placeholder="Mobile"
                                        value={mobile}
                                        onChange={(e) => setMobile(e.target.value)}
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="formBookClassappeasesexam">

                                    <Form.Control type="text"
                                        placeholder="Class Appeared Examination"
                                        value={classappeasesexam}
                                        onChange={(e) => setClassappeasesexam(e.target.value)}
                                    />
                                </Form.Group>
                            </div>


                            <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="formBookMothertongue">

                                    <Form.Control type="text"
                                        placeholder="Mother Tongue"
                                        value={mothertongue}
                                        onChange={(e) => setMothertongue(e.target.value)}
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="formBooksubject">


                                    <Form.Control as="textarea" row={3}
                                        placeholder="Subject"
                                        value={subject}
                                        onChange={(e) => setSubject(e.target.value)}
                                    />

                                </Form.Group>

                            </div>



                            {/* <Form.Select className="mb-3" controlId="formBookJobcategary">
                
              

        
      
      <option value={ jobcategary} onChange={(e) => setJobcategary(e.target.value)}>One</option>
      <option value={ jobcategary} onChange={(e) => setJobcategary(e.target.value)}>Two</option>
      <option value={ jobcategary} onChange={(e) => setJobcategary(e.target.value)}>Three </option>
      
      
      
     
     
     
    
        </Form.Select> */}


                            {/* <Form.Group className="mb-3" controlId="formBookJobcategary">
          <Form.Label>Disabled select menu</Form.Label>
          <Form.Select >
            <option>Animation</option>
            <option>Game 2D </option>
            <option>VFX</option>
          </Form.Select>
             </Form.Group> */}

                            {/* <ButtonGroup aria-lable="Basic example" className="mb-3">
                    <Button 
                    disabled={flag} variant="success" 
                    onClick={(e) => {
                    setStatus("Available");
                    setFlag(true);
                    }}
                    >
                        Available
                        </Button>
                        <Button variant="danger" 
                        onClick={(e) => {
                        setStatus("Not Available");
                        setFlag(false);
                        }}
                        >Not Available</Button>
        
                </ButtonGroup> */}







                        </div>

                        <button className="newbuton" type="Submit">

                            Submit

                        </button>
                    </div>
                </div>

            </Form>






        </>


    )
}
export default AddStudent;