import './App.css';

import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';


import Home from "./components/pages/Home";
import Aboutus from './components/pages/Aboutus';
import Footer from "./components/inc/Footer";
import Workpage from './components/pages/Workpage';
import Contactus from './components/pages/Contactus';
import Studentsform from './components/pages/Studentsform';
import Centreform from './components/pages/Centreform';
import Workshop from './components/pages/Workshop';
import DocWorkshop from './components/pages/DocWorkshop';


function App() {
  return (
    <Router>
    <div>
      <Routes>
      <Route axact path="/" element={<Home/>}/>
      <Route axact path="/about-us" element={<Aboutus/>}/>
      <Route axact path="/our-work" element={<Workpage/>}/>
      <Route axact path="/contact-us" element={<Contactus/>}/>
      <Route axact path="/students-form" element={<Studentsform/>}/>
      <Route axact path="/centre-registration" element={<Centreform/>}/>
      <Route axact path="/Workshop" element={<Workshop/>}/>
      <Route path="/MorePhoto/:docId" element={<DocWorkshop/>}/>
      </Routes>
    <Footer/>
  
    </div>

    </Router>
  );
}

export default App;
