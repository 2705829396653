import React, { useState, useEffect } from 'react';
import { useNavigate} from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";

import {db} from '../../firebase'







function Worknew () {
    const [ setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
      const [showPerPage, setShowPerPage] = useState(9);
      const [pagination, setPagination] =useState({
          start: 0,
          end: showPerPage,
      });
  
      const onPaginationChange = (start,  end) =>{
          setPagination({start: start, end: end});
      };
      const [searchTerm, setsearchTerm] = useState("");
      const [workphoto, setWorkphoto] = useState([])
      const navigate = useNavigate()
  
      const workphotoCollectionRef = collection(db, "OUR-WORK");
      useEffect(() => {
  
          const getWorkphoto= async () => {
              const data = await getDocs(workphotoCollectionRef);
              setWorkphoto(data.docs.map((doc) => ({...doc.data(), id: doc.id })))
          }
  
          getWorkphoto()
      }, [])
    return (
      
<>




<div className="album py-0">
        
            <div className="container">

                <div className="row">
               
                <div className="col-md-6">
                        
                        <div className="card-body">
                        <input type="text" className="form-control" placeholder="Search..." name="location" 
                        onChange ={(e) => {
                          setsearchTerm(e.target.value);
                        }}
                        />
                                <div className="d-flex justify-content-between align-items-center">
                                   
                                    
                            

                            </div>
                          
                    
                        </div>

                      
                    </div>
             
                    </div>
                  
                    </div>
                    </div>
<div className="album py-2">
        
        <div className="container">

            <div className="row">
            {workphoto.filter((val)=>{
      if (setsearchTerm === ""){
        return val;
      } else if (
        val.title.toLowerCase().includes(searchTerm.toLowerCase()) 

    
      
       
  
      ){
        return val;
      }
    }).slice(pagination.start, pagination.end).map((workphoto) => { return (
       
            <div className="col-md-3">
                        <div className="card mb-5 border-White box shadow">
                            
                        <img onClick={() => {navigate(`/MorePhoto/${workphoto.id}`)}} className="card-img-top" src={workphoto.img}   alt={workphoto.title} />
                      
                       
                        <p onClick={() => {navigate(`/MorePhoto/${workphoto.id}`)}} type="button" >
                                          
                                  

                            <div className="card-body">
                           
                            <h5 className="card-text mb-1">{workphoto.title}</h5>
                  
                        
                            <p style={{color:"grey"}} className="card-text"> {workphoto.des}</p>
                           
                         

                            </div>
                            </p>
                            </div>
                               
                            </div>
                                )})} 
                            </div>
                    
                            </div>
                            </div>

</>
        
        );
    }
    
    export default Worknew;
