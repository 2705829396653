import React from 'react'
import Navbar from '../inc/Navbar'
import AddStudent from '../AddStudent'
import { Link } from "react-router-dom";
import student from "../images/form.pdf"
import form from "../images/form_font.jpg";
import form2 from "../images/S-F2.jpg";
import qr from "../images/draftsmanship payment.jpeg";

import ScrollToTop from "react-scroll-to-top";
function Studentsform() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <br />
            <br />
            <ScrollToTop smooth top="100" color="Darkblue"/>
            <div className='backgroundc'>
                <div className="album py-1">
                    <div className="container mt-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link style={{ color: "white" }} to="/" >HOME</Link></li>
                                <li style={{ color: "white" }} className="breadcrumb-item active" aria-current="page">STUDENT FORM</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <br />
            <div className="album py-2">
                <div className="container ">
                    <center>
                        <div className='color'>
                            <h1 className='mb-1'><b>DRAFTSMANSHIP ART ACADEMY</b></h1>
                            <p className='mb-0' style={{ color: "maroon" }}><small>REGD UNDER THE INDIAN TRUST ACT 1882</small></p>
                            <p className='mb-0' style={{ color: "maroon" }}><small>Government Regd. No. IV - 190200103/2021</small></p>
                            <p style={{ color: "maroon" }}><small>AN ISO CERTIFIED - 9001:2015 || MSME UDYAM REGISTRATION UDYAM-WB-13-060745</small></p>

                        </div>
                        <h2 style={{ color: "grey" }}><b>Student Form</b></h2>
                    </center>
                </div>
            </div>
            <br />
            <AddStudent/>
            <br />
            <div className="album py-2">
                <div className="container ">
                    <h4>Payment  QR </h4>
                </div>
            </div>
            <center>
                <div className="album py-0">
                    <div className="container ">
                        <div className="row">
                            <div className="col-md-6 mt-1">
                                <div className="card" >
                                    <img src={qr} className="card-img-top" alt="qr" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </center>
            <br />
            <div className="album py-1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 mt-3">
                            <div className="card">
                                <img src={form} className="card-img-top" alt="form" />
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className="card">
                                <img src={form2} className="card-img-top" alt="..."/>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className="album py-3">
                <div className="container ">
                    <center>
                        <a href={student} target="_blank" rel="noopener noreferrer">Offline Download Form</a>
                    </center>
                </div>
            </div>
            <br />
        </div>
    )
}

export default Studentsform