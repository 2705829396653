import React from 'react'
import Navbar from '../inc/Navbar'

import {Link} from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import Worknew from './Worknew';
function Workpage() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <ScrollToTop smooth top="100" color="Darkblue"/>
         
        <div className='backgroundc'>
            <div className="album py-1">
                <div className="container mt-3">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li  className="breadcrumb-item"><Link style={{color:"white"}} to="/" >HOME</Link></li>
                            <li style={{color:"white"}}   className="breadcrumb-item active" aria-current="page">OUR WORK</li>
                        </ol>
                    </nav>
                </div>
            </div>
            </div>
        <br/>
        <Worknew/>
    </div>
  )
}

export default Workpage