


import React, { useEffect, useState } from 'react'
import { collection, getDoc, doc, } from 'firebase/firestore'
import { db } from '../../firebase'
import { Link, useParams } from 'react-router-dom'

import Navbar from '../inc/Navbar';



function DocWorkshop() {


    const userinfoRef = collection(db, "Appointments")
    const [workphoto, setWorkphoto] = useState()
    const params = useParams()
    useEffect(() => {
        getData()
    }, [])
    async function getData() {
        try {
            const workphotoTemp = await getDoc(doc(db, 'OUR-WORK', params.docId))
            setWorkphoto(workphotoTemp.data())

        } catch (error) {
            console.log(error.message)
        }
    }


    return (
        <>
            <Navbar/>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
         
            <div className='container-sm' >

                <div className='DocConsultant' style={{ margin: 0 }}>
                    {/* <div className='doc-div' style={{ display: "flex", justifyContent: "space-between"}}>

            <div className='DocConsultant' style={{ display: "block",margin:50 }}> */}
                    <div className="album py-3">

                        <div className="container">

                            <div className="row">
                                <center>
                                <div className="col-md-10">
                                    <div className="card mb-4 box ">
                                        <img className="card-img-top" src={workphoto && workphoto.img} alt= {workphoto && workphoto.title}/>

                                        <div className="card-body">
                                            {/* <Image style={{ display: "block", width: 350, height: 250 }} src={doctor && doctor.docImg} rounded/> */}
                                            <h4 style={{ color: "grey" }} className="card-text"> {workphoto && workphoto.title}</h4>


                                            <p style={{ color: "grey" }} className="card-text"> {workphoto && workphoto.des}</p>

                                            <hr />



                                        </div>
                                        <h4 style={{ color: "grey" }} className="card-text mb-4"> {workphoto && workphoto.title2} </h4>

                                        <img className="card-img-top" src={workphoto && workphoto.img2} alt= {workphoto && workphoto.title2}/>

                                        <div className="card-body">
                                            {/* <Image style={{ display: "block", width: 350, height: 250 }} src={doctor && doctor.docImg} rounded/> */}
                                            <h4 style={{ color: "grey" }} className="card-text"> {workphoto && workphoto.title2}</h4>


                                            <p style={{ color: "grey" }} className="card-text"> {workphoto && workphoto.des2}</p>

                                            <hr />



                                        </div>
                                        <h4 style={{ color: "grey" }} className="card-text mb-4"> {workphoto && workphoto.title3} </h4>

                                        <img className="card-img-top" src={workphoto && workphoto.img3} alt= {workphoto && workphoto.title3}/>

                                        <div className="card-body">
                                            {/* <Image style={{ display: "block", width: 350, height: 250 }} src={doctor && doctor.docImg} rounded/> */}
                                            <h4 style={{ color: "grey" }} className="card-text"> {workphoto && workphoto.title3}</h4>


                                            <p style={{ color: "grey" }} className="card-text"> {workphoto && workphoto.des3}</p>

                                            <hr />



                                        </div>
                                        <h4 style={{ color: "grey" }} className="card-text mb-4"> {workphoto && workphoto.title4} </h4>

                                        <img className="card-img-top" src={workphoto && workphoto.img4} alt= {workphoto && workphoto.title4}/>

                                        <div className="card-body">
                                            {/* <Image style={{ display: "block", width: 350, height: 250 }} src={doctor && doctor.docImg} rounded/> */}
                                            <h4 style={{ color: "grey" }} className="card-text"> {workphoto && workphoto.title4}</h4>


                                            <p style={{ color: "grey" }} className="card-text"> {workphoto && workphoto.des4}</p>

                                            <hr />



                                        </div>
                                    </div>
                                </div>
                                </center>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </>
    )
}

export default DocWorkshop