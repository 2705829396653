
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage'
import "firebase/storage";



const firebaseConfig = {
    apiKey: "AIzaSyCkR0X4RDjsfpia_6ajL69u1S69cOQlPTM",
    authDomain: "draftman-ship.firebaseapp.com",
    projectId: "draftman-ship",
    storageBucket: "draftman-ship.appspot.com",
    messagingSenderId: "431786432358",
    appId: "1:431786432358:web:6368973c3c3e5ecb6f5aa7",
    measurementId: "G-C0G1QVP90D"

};
//init firebase app
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const db = getFirestore(app);




export default app;