import React from 'react'
import Navbar from '../inc/Navbar'
import Slider from '../inc/Slider'
import Noticeboard from './Noticeboard'
import about from "../images/about.jpeg";
import "./Home.css";
import ScrollToTop from "react-scroll-to-top";
import Worknew from './Worknew';

function Home() {
  return (
    <div>
      <Navbar />
      <br />
      <br />
      <ScrollToTop smooth top="100" color="Darkblue" />


      <div className="album py-2">

        <div className="container">
          <h3>draftsmanship art academy</h3>
        </div>
      </div>
      <br />
      <Slider />

      <div className='marqueeback'>
        <marquee>
          <div className="album py-1">
            <div className="container">
              <h5 style={{ color: "white" }} className='mt-3'>Welcome to Draftsmanship Art Academy, exam board of art & culture.....Drawing, Music, Dance, Song, Yoga.</h5>
            </div>
          </div>
        </marquee>


      </div>

      <div className="album py-1">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mt-3">
              <div className="card" >
                <img src={about} className="card-img-top" alt="about" />
                <div class="card-body">
                  <h5 class="card-title"><center>About Us</center></h5>
                  <a href='about-us'>
                    <div className="btn-grad51" type="submit">Learn More</div>
                  </a>

                </div>
              </div>

            </div>
            <div className="col-md-6 mt-3">
              <div className="card border-primary mb-3">
                <div className="card-header"><center>NOTICE BOARD</center></div>
                <div className="card-body text-primary">
                  <Noticeboard />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />

      <div className='back'>

        <br />
        <div className="album py-1">
          <div className="container">
            <h4>OUR <b style={{ color: "grey" }}>WORK</b></h4>
          </div>
        </div>
        <Worknew />
        <br />
        <br />
      </div>


    </div>
  )
}

export default Home